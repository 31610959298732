body {
  background-color: #e0e0e0;
}

.App {
  font-family: Arial, sans-serif;
  margin: 20px;
  color: #333;
}

h1 {
  text-align: center;
}

.bet-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.bet-form input,
.bet-form select,
.bet-form button {
  margin: 5px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bet-form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bet-form button:hover {
  background-color: #45a049;
}

.bet-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.summary {
  text-align: center;
  margin-bottom: 20px;
}

.summary h2 {
  color: #4CAF50;
}

.table-container {
  margin-bottom: 20px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.table-wrapper {
  display: flex;
  justify-content: center;
}

.table-wrapper table {
  width: 90%;
  max-width: 1000px;
  border-collapse: collapse;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.table-wrapper th,
.table-wrapper td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.table-wrapper th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.table-wrapper tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-wrapper tbody tr:hover {
  background-color: #f1f1f1;
}

.table-wrapper th {
  background-color: #4CAF50;
  color: white;
}

.table-wrapper td {
  font-size: 14px;
}

.table-wrapper tbody tr {
  transition: background-color 0.3s ease;
}

.tac {
  text-align: center;
}

.green {
  color: #4CAF50;
}

.red {
  color: #DC143C;
}

.export-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.export-buttons button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-buttons button:hover {
  background-color: #45a049;
}

.export-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.rare-stats-text {
  display: flex;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.bet-results-table-wrapper {
  display: flex;
  justify-content: center;
  max-height: 500px;
  overflow-y: auto;
  position: relative;
}

.bet-results-table-wrapper table {
  width: 90%;
  max-width: 1000px;
  border-collapse: collapse;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.bet-results-table-wrapper thead {
  position: sticky;
  top: 0;
  background-color: #f4f4f4; /* Ensure this matches your header background color */
  z-index: 1;
}

.bet-results-table-wrapper th,
.bet-results-table-wrapper td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.note-input {
  width: 100%;
  max-width: 250px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.bet-form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Add some spacing */
}

.bet-form button:hover {
  background-color: #45a049;
}

.bet-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.bet-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.refresh-button-container {
  margin-left: 10px;
}

.refresh-button-container button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.refresh-button-container button:hover {
  background-color: #45a049;
}

.refresh-button-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.export-button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color: #4CAF50;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-button:hover {
  background-color: #45a049;
}

.export-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

